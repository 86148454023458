<template>
  <div>
    <!--    添加文件-->
    <el-dialog
      :title="$t('label.file.library.selectFile2Lib')"
      :visible.sync="dialogAddVisibleProp"
      class="folder"
      width="709px"
      :before-close="addWorkClose"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      append-to-body
    >
      <div style="position: relative">
        <div class="work">
          <!--          上传新文件-->
          <el-button @click="addAppendix" type="primary" size="small">{{
            $t("label.file.uploadfiles")
          }}</el-button>
          <!--          单个文件最大支持-->
          <div class="upload-tip">
            {{ $t("vue_label_file_single_file_size") + " " + showSize }}
            <el-popover width="180" trigger="hover" placement="top">
              <div>
                <!--                建议上传文件小于30MB,-->
                <!--                <br />否则可能导致页面加载缓慢-->
                <div
                  style="
                    font-size: 12px;
                    word-break: break-word;
                    text-align: left;
                  "
                >
                  {{
                    $t("vue_label_file_upload_tip_newmsg", {
                      size: 30,
                    })
                  }}
                  <!-- {{$t('vue_label_file_upload_tip_msg')}} -->
                </div>
              </div>
              <!--              <img :src="explainImg" slot="reference" class="tipsBox" />-->
              <svg aria-hidden="true" class="tipsBox" slot="reference">
                <use :href="explainImg"></use>
              </svg>
            </el-popover>
          </div>
        </div>
        <div class="inp_right">
          <el-input v-model="searchWork" placeholder=""></el-input>
          <i class="el-icon-search icon"></i>
        </div>
        <el-tabs
          :tab-position="tabPosition"
          class="tabWork"
          v-model="activeName"
          @tab-click="tabWorkFun"
        >
          <el-tab-pane
            :name="val.code"
            :label="val.name"
            v-for="(val, index) in tabWorkArr"
            :key="index"
          >
            <div class="work_cont" v-loading="loading">
              <ul v-if="activeName !== 'lib'" class="work_ul">
                <li v-for="(item, i) in workList" :key="i">
                  <el-checkbox
                    v-model="item.checkedWork"
                    @change="changeCheckValue(item, i)"
                  ></el-checkbox>
                  <dl>
                    <dt>
                      <!-- <img :src="allIcon[item.type] || defaultIcon" /> -->
                      <svg
                        style="width: 100%; height: 100%"
                        class="icon"
                        aria-hidden="true"
                      >
                        <use :href="allIcon[item.type] || defaultIcon"></use>
                      </svg>
                    </dt>
                    <dd>
                      <p>{{ item.name }}</p>
                      <p>
                        <span>{{
                          item.lastmodifydate | datetimeFormat(countryCode)
                        }}</span>
                        <span>{{ switchSize(item.filesize) }}</span>
                      </p>
                    </dd>
                  </dl>
                </li>
              </ul>
              <!--              文件库-->
              <div v-if="innerLib" class="inner-lib" @click="goPrePage">
                返回
              </div>
              <ul
                v-if="activeName === 'lib'"
                :class="innerLib ? 'inner-ul' : ''"
              >
                <!--                    没有更多数据了 label.productsales.nodata-->
                <li v-if="workList.length === 0" class="no-file-data">
                  {{ $t("label.productsales.nodata") }}！
                </li>
                <li v-for="(item, i) in workList" :key="i">
                  <p
                    v-show="item.type === 'lib' || item.type === 'folder'"
                    class="placeholder-space"
                  ></p>
                  <el-checkbox
                    v-model="item.checkedWork"
                    v-if="item.type !== 'lib' && item.type !== 'folder'"
                    @change="changeCheckValue(item, i)"
                  ></el-checkbox>
                  <dl>
                    <dt>
                      <!-- <img
                        @click="openFolder(item)"
                        :src="
                          allIcon[item.type] ||
                            allIcon[item.fileType] ||
                            defaultIcon
                        "
                      /> -->
                      <svg
                        class="icon"
                        style="width: 28px; height: 28px"
                        aria-hidden="true"
                        @click="openFolder(item)"
                      >
                        <use
                          :href="
                            allIcon[item.type] ||
                            allIcon[item.fileType] ||
                            defaultIcon
                          "
                        ></use>
                      </svg>
                    </dt>
                    <dd>
                      <template
                        v-if="item.type !== 'lib' && item.type !== 'folder'"
                      >
                        <p>{{ item.name }}</p>
                      </template>
                      <template v-else>
                        <p class="lib-folder-name" @click="openFolder(item)">
                          {{ item.name }}
                        </p>
                      </template>
                      <p>
                        <span>{{
                          item.lastmodifydate | datetimeFormat(countryCode)
                        }}</span>
                        <span>{{ switchSize(item.filesize) }}</span>
                      </p>
                    </dd>
                    <span
                      @click="openFolder(item)"
                      style="cursor: pointer"
                      v-if="item.type === 'lib' || item.type === 'folder'"
                    >
                      <!--                      <img-->
                      <!--                        :src="folderImg"-->
                      <!--                        style="width:28px;height: 28px;transform:rotate(-90deg);"-->
                      <!--                        alt=""-->
                      <!--                      />-->
                      <svg
                        class="icon"
                        aria-hidden="true"
                        style="
                          width: 28px;
                          height: 28px;
                          transform: rotate(-90deg);
                        "
                        slot="reference"
                      >
                        <use :href="folderImg"></use>
                      </svg>
                    </span>
                  </dl>
                </li>
              </ul>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>

      <div slot="footer" class="dialog-footer" style="position: relative">
        <!--        已选择了{{ selectedNum }}个文件，最多单次添加{{ originNum }}个文件 label.addfile.tips-->
        <span style="font-size: 12px; position: absolute; top: 0; left: 20px">
          <!--          {{$t('label.totalof') +' '+ selectedNum +' '+ $t('label.file')}}，{{$t('vue_label_file_add_most_once') + ' '+originNum +' '+ $t('label.file')}}-->
          {{
            $t("label.addfile.tips", {
              selectedNum: selectedNum,
              originNum: originNum,
            })
          }}
        </span>
        <el-button
          @click="addWorkClose"
          v-loading="buttonLoading"
          size="small"
          >{{ $t("label.cancel") }}</el-button
        >
        <el-button
          type="primary"
          @click="addWorkSubmit"
          v-loading="buttonLoading"
          size="small"
          >{{ $t("label.add") }}({{ selectedNum }})</el-button
        >
      </div>
    </el-dialog>

    <!-- 上传新文件 -->
    <el-dialog
      :title="$t('label.file.uploadfiles')"
      width="35%"
      top="12%"
      append-to-body
      :close-on-click-modal="false"
      :visible.sync="addAppendixDialog"
    >
      <el-upload
        class="upload-demo"
        ref="upload"
        :action="action"
        :limit="originLimit"
        :multiple="true"
        :on-exceed="handleExceed"
        :http-request="beforeUpload"
        :file-list="fileList"
        :auto-upload="false"
      >
        <el-button slot="trigger" size="small" type="primary">{{
          $t("label.selectfile")
        }}</el-button>
      </el-upload>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="addAppendixDialog = false"
                   size='mini'>取消</el-button> -->
        <el-button type="primary" @click="saveFile" size="mini">{{
          $t("label.confirm")
        }}</el-button>
      </span>

      <!-- 上传进度条 -->
      <!-- 进度大于0并且小于100的时候显示 -->
      <el-progress
        class="progreccBox"
        color="#409eff"
        :percentage="percentage"
        v-show="percentage > 0 && percentage < 100"
      ></el-progress>
    </el-dialog>
  </div>
</template>

<script>
import * as fileApi from "./api.js";
import FILEICON from "@/views/ccFileCenter/config/fileIconLibrary";
import * as Time from "@/utils/time";

export default {
  name: "AddFiles",
  props: {
    // 是否来自文件中心
    isFromFileCenter: {
      type: Boolean,
      default: true,
    },
    dialogAddVisible: {
      type: Boolean,
      default: false,
    },
    // 添加限制
    originNum: {
      type: [Number,String],
      default: 10,
    },
    // 上传限制
    originLimit: {
      type: Number,
      default: 10,
    },
    // chatter:聊天，file:文件，attachment:附件
    updateType: {
      type: String,
      default: "file",
    },
    identification: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      buttonLoading: false,
      innerLib: false,
      libArr: [],
      // dialogAddVisibleProp: this.dialogAddVisible,
      fileList: [],
      selectedNum: 0,
      showSize: "10MB",
      defaultIcon: FILEICON.default, // 文件缺省图标
      allIcon: FILEICON, // 文件图标
      loading: false,
      explainImg: "#icon-questionmark",
      folderImg: "#icon-optionslist",
      addAppendixDialog: false,
      action: "/test",
      fileType: [],
      size: 1024 * 10,
      multiple: true,
      searchWork: "",
      tabPosition: "left", //添加文件
      activeName: "ownedbyme",
      workList: [],
      newList: [],
      dateFormat: Time.dateFormat(this.$cookies.get("countryCode")),
      datetimeFormat: Time.datetimeFormat(this.$cookies.get("countryCode")),
      timeFormat: Time.timeFormat(this.$cookies.get("countryCode")),
      countryCode: this.$cookies.get("countryCode"),
      pageNum: 1,
      pageSize: 10,
      noDataTip: false,
      isScroll: false,
      scrolling: true, // 文件列表是否支持滚动加载
      percentage: 0, // 上传文件进度
      // 上传文件数据量
      uploadNum: 0
    };
  },
  mounted() {},
  methods: {
    // 初始化页面
    initPage() {
      this.activeName = "ownedbyme";
      this.tabWorkFun();
      this.buttonLoading = false;
      this.selectedNum = 0;
      // 目前限制附件大小先写死
      // 文件-获取文件大小限制
      this.queryLimitText();
    },
    // 选中文件
    changeCheckValue(item, i) {
      let checkedFileList = this.workList.filter((file) => {
        return file.checkedWork;
      });
      if (checkedFileList.length > Number(this.originNum)) {
        this.workList[i].checkedWork = false;
        this.$message.warning(this.$i18n.t("vue_label_file_checked_num_limit"));
        return false;
      } else {
        this.selectedNum = checkedFileList.length;
      }
    },
    // 文件大小转换
    switchSize(filesize) {
      if (filesize) {
        if (filesize < 0.1 * 1024) {
          //如果小于0.1KB转化成B
          return filesize.toFixed(2) + "B";
        } else if (filesize < 0.1 * 1024 * 1024) {
          //如果小于0.1MB转化成KB
          return (filesize / 1024).toFixed(2) + "KB";
        } else if (filesize < 0.1 * 1024 * 1024 * 1024) {
          //如果小于0.1GB转化成MB
          return (filesize / (1024 * 1024)).toFixed(2) + "MB";
        } else {
          //大于100M转化为GB
          return (filesize / (1024 * 1024 * 1024)).toFixed(2) + "GB";
        }
      } else {
        return "0KB";
      }
    },
    // 确认添加
    addWorkSubmit() {
      let checkedFileList = this.workList.filter((file) => {
        return file.checkedWork;
      });
      // workList是所有文件列表，checkedFileList是选中文件列表
      if (checkedFileList.length === 0) {
        // 请至少选择一个文件
        this.$message({
          showClose: true,
          message: this.$i18n.t("label.unselectfile"),
          type: "warning",
        });
        return false;
      }
      // 注：从文件库里面选择的文件id为fileInfoId，文件列表为id(是以fil开头的，例如"fil20208EF4E459hNmcE")
      this.buttonLoading = true;
      this.$emit("addSubmit", checkedFileList);
      this.pageNum = 1; //确定之后页码归1
    },
    // 打开文件库、文件夹
    openFolder(item) {
      if (item.type === "lib" || item.type === "folder") {
        this.getLibList(item.id);
        this.loading = true;
      }
    },
    // 文件库返回上一页
    goPrePage() {
      let len = this.libArr.length;
      let flag = len - 2;
      let id;
      if (len >= 2) {
        id = this.libArr[flag];
        this.libArr.length = flag;
      } else {
        id = "";
        this.libArr = [];
      }
      this.loading = true;
      this.getLibList(id);
    },
    // 获取文件库
    getLibList(libid, keyword) {
      let op = {
        libraryId: libid,
        searchWord: keyword,
      };
      if (libid) {
        this.libArr.push(libid);
      }
      fileApi
        .getQueryLibs(op)
        .then((res) => {
          this.workList = res.data.list || [];
          this.workList.forEach((item) => {
            this.$set(item, "checkedWork", false);
          });
          this.newList = res.data.list;
          if (this.libArr.length > 0) {
            this.innerLib = true;
          } else {
            this.innerLib = false;
          }
          this.loading = false;
        })
        .catch(() => {});
    },
    //获取文件列表
    tabWorkFun(tab) {
      this.loading = true;
      this.innerLib = false;
      this.libArr = [];
      if (this.isScroll === false) {
        this.pageNum = 1;
      } else if (tab) {
        this.pageNum = 1;
      }

      if (tab) {
        this.selectedNum = 0;
      }
      const { pageNum, pageSize } = this;
      if (this.activeName === "lib") {
        this.getLibList();
      } else {
        let data = {
          searchWord: this.searchWork,
          type: this.activeName,
          page: pageNum,
          pageSize: pageSize,
        };
        fileApi.QueryListTab(data).then((res) => {
          this.scrolling = false;
          setTimeout(() => {
            this.scrolling = true;
          }, 500);

          //文件列表加分页滚动加载
          if(this.isScroll){
            if (res.data.listFile.length === 0 && this.pageNum === 1) {
              this.workList = [];
            } else if (this.workList.length === 0 || this.pageNum === 1) {
              this.workList = res.data.listFile;
              this.pageNum++;
            } else {
              this.workList = [...this.workList, ...res.data.listFile];
              this.pageNum++;
            }
          }else{
            this.workList = res?.data?.listFile || [];
          }
          if (this.noDataTip === false) {
            this.scroll();
          }
          // 判断是否有更多数据
          if (!res.data.isMore) {
            this.noDataTip = true;
          } else if (res.data.isMore === true) {
            this.noDataTip = false;
          }
          this.workList.forEach((item) => {
            if (item.checkedWork === true) {
              this.$set(item, "checkedWork", true);
            } else {
              this.$set(item, "checkedWork", false);
            }
          });
          // this.newList = this.workList;
          this.loading = false;
        });
      }
    },
    // 节流函数
    fnThrottle(method, delay, duration) {
      var that = this;
      var timer = this.timer;
      var begin = new Date().getTime();
      return function () {
        var current = new Date().getTime();
        clearTimeout(timer);
        if (current - begin >= duration) {
          method();
          begin = current;
        } else {
          that.timer = setTimeout(function () {
            method();
          }, delay);
        }
      };
    },
    // //滚动事件
    scroll() {
      let that = this;
      let dom = document.querySelectorAll(".work_ul");
      dom.forEach((item) => {
        item.addEventListener("scroll", function () {
          if (that.scrolling) {
            that.isScroll = true;
            const scrollDistance =
              item.scrollHeight - item.scrollTop - item.clientHeight;
            if (scrollDistance <= 0) {
              // this.isScroll=true
              //等于0证明已经到底，可以请求接口
              that.fnThrottle(that.append, 100, 500)();
            }
          }
        });
      });
    },
    append() {
      if (!this.noDataTip) {
        this.tabWorkFun();
      }
    },
    // 打开上传
    addAppendix() {
      this.$refs.upload && this.$refs.upload.clearFiles();
      this.addAppendixDialog = true;
    },
    // 获取文件展示大小
    queryLimitText() {
      fileApi.queryLimit({ type: this.updateType }).then((res) => {
        this.showSize = res.data;
        this.percentage = 0;
        this.size =
          Number(res.data.slice(0, res.data.length - 2)) * 1024 * 1024;
      });
    },
    // 取消添加
    addWorkClose() {
      this.searchWork = "";
      this.buttonLoading = false;
      this.$emit("close", false);
      this.pageNum = 1;
      this.uploadNum = 0
    },
    // 文件上传前执行的事件
    beforeUpload(file) {
      file = file.file
      let size = file.size;
      if (size > this.size) {
        // 文件大小超过限制
        // 附件大小超过{size}M，上传失败
        this.$message.error(
          file.name +
            this.$i18n.t("vue_label_file_size_limit_new", {
              size: this.size / (1024 * 1024),
            })
        );
        return false;
      }
      let formData = new FormData();
      formData.append("file", file);
      formData.append("fileName", file.name);
      formData.append("groupid", "");
      formData.append("libid", "");
      formData.append("parentid", "");
      formData.append("isFromEmail", "");
      /*
       * 获取上传文件时的进度
       * 1.进度条的实现是依靠axios中提供的onUploadProgress函数
       * 2.该函数提供了文件已上传部分的大小progressEvent.loaded、文件总大小progressEvent.total
       * 3.利用这两个数据就可以计算出已经上传文件的进度
       */
      let config = {
        onUploadProgress: (progressEvent) => {
          this.percentage = parseInt(
            (progressEvent.loaded / progressEvent.total) * 100
          );
        },
      };
      this.uploadNum++
      fileApi
        .upload(formData, config)
        .then((res) => {
          this.uploadNum--
          if (res.result) {
            // 上传成功 label.uploadsuc
            this.$message.success(
              `${res.data.name + " " + this.$i18n.t("label.uploadsuc")}！`
            );
            this.activeName = "ownedbyme";
            this.pageNum = 1;
            this.tabWorkFun();
            this.addAppendixDialog = false;
          } else {
            // 上传失败 label.file.upload.fail
            this.$message.warning(this.$i18n.t("label.file.upload.fail"));
          }
          if(!this.uploadNum){
            // 关闭弹窗
            this.addAppendixDialog = false;
            this.percentage = 0;
          }
        })
        .catch(() => {
          this.uploadNum--
          return false;
        });
    },
    handleExceed() {
      // 文件个数超出预设，如果需要替换文件，请先删除已上传的文件
      this.$message.warning(this.$i18n.t("vue_label_file_num_limit_tip"));
    },
    saveFile() {
      this.$refs.upload && this.$refs.upload.submit()
      this.addAppendixDialog = false;
      this.percentage = 0;
    },
    //获取上传成功信息
    UploadSuccess(val) {
      this.$emit("UploadSuccesses", val);
    },
  },
  watch: {
    searchWork() {
      this.pageNum = 1;
      this.loading = true;
      this.fnThrottle(this.tabWorkFun, 500, 1000)();
    },
  },
  computed: {
    dialogAddVisibleProp() {
      return this.dialogAddVisible;
    },
    tabWorkArr() {
      if (this.isFromFileCenter) {
        const arr = [
          {
            name: this.$i18n.t("vue_label_file_my_file"),
            code: "ownedbyme",
          },
          {
            name: this.$i18n.t("label.file.sharedwithme"),
            code: "sharedwithme",
          },
          {
            name: this.$i18n.t("label.report.recently"),
            code: "recent",
          },
          {
            name: this.$i18n.t("label.chatter.following"),
            code: "following",
          },
        ];
        return arr;
      } else {
        const arr = [
          {
            name: this.$i18n.t("vue_label_file_my_file"),
            code: "ownedbyme",
          },
          {
            name: this.$i18n.t("label.file.sharedwithme"),
            code: "sharedwithme",
          },
          {
            name: this.$i18n.t("label.report.recently"),
            code: "recent",
          },
          {
            name: this.$i18n.t("label.chatter.following"),
            code: "following",
          },
          {
            name: this.$i18n.t("vue_label_file_libraray"),
            code: "lib",
          },
        ];
        return arr;
      }
    },
  },
};
</script>
<style scoped lang="scss">
// 调整进度条位置
.progreccBox {
  ::v-deep .el-progress {
    top: -4px;
  }
}
.folder {
  ::v-deep .el-dialog__header {
    border-bottom: 1px solid #ccc;
    padding: 20px;
  }
  ::v-deep .el-dialog__footer {
    border-top: 1px solid #ccc;
    padding: 20px;
  }
  ::v-deep .el-dialog__body {
    padding: 0;
  }
  .tabWork {
    width: 100%;
    height: 370px;
    ::v-deep .el-tabs__item {
      text-align: left !important;
      height: 32px !important;
      padding: 0 20px;
      box-sizing: border-box;
    }
    ::v-deep .el-tabs__header .is-left {
      width: 176px !important;
      font-size: 12px !important;
      font-weight: 400 !important;
      line-height: 32px;
      .el-tabs__active-bar {
        background: none !important;
      }
      .el-tabs__item:hover,
      .el-tabs__item.is-active {
        color: #409eff !important;
        cursor: pointer;
        background: #f3f2f2;
      }
    }
    .work_cont {
      height: 360px;
      overflow: hidden;
      //width: 580px;
      //margin-top: 10px;
      ul {
        margin-left: 5px;
        overflow-y: auto;
        height: 100%;
        li {
          display: flex;
          align-items: center;
          height: 60px;
          border-top: 1px solid #dedcda;
          //margin-bottom: 28px;
          //cursor: pointer;
          dl {
            display: flex;
            margin-bottom: 0;
            margin-left: 24px;
            align-items: center;
            dt {
              width: 30px;
              height: 30px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            dd {
              margin-left: 10px;
              margin-bottom: 0;
              p:first-child {
                font-weight: 400;
                color: #333333;
                line-height: 21px;
                margin-bottom: 0;
                font-size: 14px;
                width: 370px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                word-break: break-all;
              }
              p:nth-child(2) {
                font-size: 10px;
                font-weight: 400;
                color: #666666;
                line-height: 15px;
                margin-bottom: 0;
                :nth-child(2) {
                  margin-left: 28px;
                }
              }
            }
          }
        }
      }
    }
  }
  .work {
    width: 176px;
    border-right: 2px solid #e4e7ed;
    padding: 20px 0 10px 20px;
    .el-button {
      width: 134px;
      height: 30px;
      background: #006dcc;
      border-radius: 3px;
      padding-left: 0;
      padding-right: 0;
    }
    .upload-tip {
      width: 90%;
      font-weight: 400;
      color: #666666;
      line-height: 15px;
      //height: 10px;
      font-size: 10px;
      margin-bottom: 0;
      //position: relative;
      margin-top: 7px;
      display: flex;
      align-items: center;
      word-break: break-word;
    }
    .tipsBox {
      margin-left: 5px;
      width: 10px;
      height: 10px;
      cursor: pointer;
    }
  }
  .inp_right {
    width: 480px;
    position: absolute;
    top: 15px;
    left: 190px;
    ::v-deep .el-input__inner {
      height: 30px;
    }
    .el-icon-search {
      position: absolute;
      top: 7px;
      left: 450px;
      font-size: 16px;
    }
  }
  .lib-folder-name {
    cursor: pointer;
    color: #006dcc !important;
  }
  .placeholder-space {
    width: 14px;
    height: 14px;
    display: inline-block;
  }
  .no-file-data {
    display: block !important;
    text-align: center;
  }
  .inner-ul {
    padding-top: 30px;
  }
  .inner-lib {
    position: absolute;
    top: 0;
    left: 0;
    color: #006dcc;
    cursor: pointer;
    background-color: #fff;
    height: 30px;
    width: 100%;
  }
}
::v-deep .el-upload-list {
  height: 26px;
}
</style>
